import React from "react";

const ReachOut = () => (
  <section className="reachOut statement f fdc ac" id="reachOut">
    <h1>get in touch.</h1>
    <h4>
      We're currently booking engagements for Q2 2025 and we'd love to chat with you about your project.
      <br class="sm-hide"></br> Tap below, give us a bit of info, and we'll be in touch.
    </h4>
    <a
      href="https://wearemage.typeform.com/to/moQNz0Y5"
      className="project--url"
      target="_blank"
      rel="noopener noreferrer"
    >
      Tap Here
    </a>
    <span className="small pb1">
      (If you just want to drop us a line, our email is in the bottom right
      there.)
    </span>
  </section>
);

export default ReachOut;
